export const allMessages = [
    {
        sender:localStorage.getItem("userName"),
        reciever:"Fuad Hossain",
        message:"Hi Fuad, Can i get the status of the project ABC?",
        time:"9:00 am",
        id:0,
    },
    {
        sender:"Fuad Hossain",
        reciever:localStorage.getItem("userName"),
        message:"Hi sir, Good Morning.",
        time:"9:01 am",
        id:1,
    },
    {
        sender:"Fuad Hossain",
        reciever:localStorage.getItem("userName"),
        message:"Currently 70% was completed, remaning 30% will be completed by the end of this month",
        time:"9:01 am",
        id:2,
    },
    {
        sender:localStorage.getItem("userName"),
        reciever:"Rajesh",
        message:"Hi Rajesh, Can i know why the project is getting delayed?",
        time:"9:10 am",
        id:3,
    },
    {
        sender:"Fuad Hossain",
        reciever:localStorage.getItem("userName"),
        message:"Hi sir, can we have a meet today, so that we can discuss the priority things to start with regarding left over part of project ABC?",
        time:"9:11 am",
        id:4,
    },
    {
        sender:localStorage.getItem("userName"),
        reciever:"Fuad Hossain",
        message:"Sure Faud, We Can have meeting at 9:00pm today.",
        time:"10:00 am",
        id:5,
    },
    {
        sender:"Rajesh",
        reciever:localStorage.getItem("userName"),
        message:"Hi sir, Good Morning.",
        time:"10:20 am",
        id:6,
    },
    {
        sender:"Rajesh",
        reciever:localStorage.getItem("userName"),
        message:"As material was reached delay by 6 days, the project timeline getting extended",
        time:"10:21 am",
        id:7,
    },
    {
        sender:localStorage.getItem("userName"),
        reciever:"Rajesh",
        message:"So how you are planning to overcome this",
        time:"10:21 am",
        id:8,
    },
    {
        sender:"Rajesh",
        reciever:localStorage.getItem("userName"),
        message:"From yesterday, we are working 2 hours extra per day to complete the project as per the intial timeline.",
        time:"10:22 am",
        id:9,
    },
    {
        sender:localStorage.getItem("userName"),
        reciever:"Rajesh",
        message:"Good, Please let me Know if u need anything from my end.",
        time:"11:00 am",
        id:10,
    },
    {
        sender:"Rajesh",
        reciever:localStorage.getItem("userName"),
        message:"Sure sir, Thank you.",
        time:"11:01 am",
        id:11,
    },
    // {
    //     sender:localStorage.getItem("userName"),
    //     reciever:"Rajesh",
    //     message:"",
    //     time:"",
    //     id:12,
    // },
    // {
    //     sender:"Fuad Hossain",
    //     reciever:localStorage.getItem("userName"),
    //     message:"",
    //     time:"",
    //     id:13,
    // },{
    //     sender:localStorage.getItem("userName"),
    //     reciever:"Fuad Hossain",
    //     message:"",
    //     time:"",
    //     id:14,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:15,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:16,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:17,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:18,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:19,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:20,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:21,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:22,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:23,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:24,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:25,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:26,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:27,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:28,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:29,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:30,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:31,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:32,
    // },

    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:33,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:34,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:35,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:36,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:37,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:38,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:39,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:40,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:41,
    // },

    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:42,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:43,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:44,
    // },{
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:45,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:46,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:47,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:48,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:49,
    // },
    // {
    //     sender:"",
    //     reciever:"",
    //     message:"",
    //     time:"",
    //     id:50,
    // }
]
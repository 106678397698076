import React from "react";

const Footer = () => {
  return (
    <div className="footer_container">
      <div>
        <p>
          Bedrock Applications, Inc. Copyright &#169; 2022.All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
